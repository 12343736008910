import React, { FunctionComponent, useEffect, useState } from 'react';
import { Card, CardMedia, CardContent, Chip, Typography } from '@material-ui/core';
import PodcastCardStyle from './podcast-card-style';
import { Link } from 'gatsby';

export interface PodcastCardProps {
  podcast: any;
}

const PodcastCard: FunctionComponent<PodcastCardProps> = ({
  podcast,
}: PodcastCardProps) => {
  const [ categories, setCategories ] = useState<any>([]);
  const [ formattedDate, setFormattedDate ] = useState<any>([]);

  useEffect(() => {
    if (podcast.date) {
      const date = new Date(podcast.date);
      setFormattedDate(date.toDateString());
    }
    if (podcast.categories.nodes) {
      setCategories(podcast.categories.nodes);
    }
  }, [podcast])

  const categoryChips = [];
  for (const [index, value] of categories.entries()) {
    categoryChips.push(
      <Chip color="secondary" className="chip" key={index} label={value.name} size="small" />
    )
  };

  return (
    <PodcastCardStyle>
      <Link to={`/podcast/post/?id=${podcast.databaseId}`} state={{ id: podcast.databaseId }}>
        <Card className="card">
          {podcast.featuredImage ? (
            <CardMedia
              className="card-image"
              image={podcast.featuredImage.node.sourceUrl}
              title={podcast.featuredImage.node.altText}
            />
          ) : null }
          <CardContent className="card-content">
            <Typography gutterBottom variant="h5" component="h2" className="title my-3">
              {podcast.title}
            </Typography>
            <Typography variant="subtitle2" className="date pb-3">
              {formattedDate}
            </Typography>
            {categoryChips ? (
              categoryChips
            ) : null}
          </CardContent>
        </Card>
      </Link>
    </PodcastCardStyle>
  );
};

export default PodcastCard
