import styled from 'styled-components'

const PodcastCardStyle = styled.div`
  .card {
    background-color: var(--darkBlue);
    cursor: pointer;
    transition: transform 0.15s ease;
    border: 1px solid var(--fadedWhite);
    display: flex;
    flex-direction: row;

    &:hover {
      transform: scale(1.025);
    }
  }
  .card-image {
    border-bottom: 1px solid var(--fadedWhite);
    width: 181px;
    display:flex;
    flex: 0 0 auto;
  }
  .card-content {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  }
  .caption {
    line-height: 1rem;
    border-left: 2px solid var(--secondary);
    border-bottom: 2px solid var(--secondary);
    font-size: 0.8rem;
  }
  .title {
    background: var(--primary);
    padding: 0.25rem;
    padding-left: 1rem;
    margin-left: -1rem;
    display: flex;
  }
  .chip, .date {
    display:flex;
  }
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    .card {
      flex-direction: column;
    }
    .card-image {
      flex: 2 0 auto;
      width: 100%;
      padding-top: 100%;
    }
    .MuiTypography-h5 {
      font-size: 1.25rem;
    }
  }
`;

export default PodcastCardStyle