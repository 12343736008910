import React, { FunctionComponent, useEffect, useState } from "react";
import { useQuery } from '@apollo/client';

import { GET_POSTS } from '../graphql/posts/get-posts';
import SEO from "../components/seo/seo"
import Header from '../components/header/header';
import PodcastCard from "../components/cards/podcast/podcast-card";
import Loading from "../components/loading/loading";

const PodcastPage: FunctionComponent = () => {
  const [ posts, setPosts ] = useState<any>([]);
  const { loading, data: postData } = useQuery(GET_POSTS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (postData) {
      const blogs = postData.posts.edges.filter(
        (blog) => blog.node.Posts.postType === "Podcast",
      );
      setPosts(blogs);
    }
  }, [postData]);

  const PodcastCards = [];
  for (const [index, value] of posts.entries()) {
    PodcastCards.push(
      <div key={value.node.databaseId} className="col-12 mb-3">
        <PodcastCard podcast={value.node} />
      </div>
    )
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <SEO title="Podcasts" />
          <Header
            title="Podcasts" 
          />
          <div className="container-fluid my-3 content">
            <div className="row align-items-top">
              { PodcastCards.length > 0 ? (
                PodcastCards
              ) : (
                <p>No podcast episodes.</p>
              ) }
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default PodcastPage
